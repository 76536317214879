import './App.css';

import React from 'react';

import { DateTime, Interval } from 'luxon';
import humanizeDuration from 'humanize-duration';

import { 
    Box, Button, Card, CardActionArea, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, TextField
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser
} from '@fortawesome/free-solid-svg-icons';


import { SignIn } from './components/UserView';

import { getDatabase, ref, set, get } from "firebase/database";
import { 
  signOut, 
} from 'firebase/auth';

import { getAppAuth, getAppDatabase } from './firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useObjectVal } from 'react-firebase-hooks/database';

// Data Model
/*
services = {
  "FirstName LastName": {
    units: 'the units we will use',
    updates: {
      'ISO date time': { value: '10', by: 'uid', initials: 'BP' }
    },
    tags: {}
  },
*/

function now() {
  return DateTime.now().toISO({ includeOffset: false, suppressSeconds: true }).split('.')[0];
}

function update(value, user) {
  return { value, user };
}

function displayInitials(name) {
  return name.split(' ').map(name=>name[0]).join('')
}

function Service({name, services, user}) {

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState('');

  const service = services[name];
  const updates = 'updates' in service ? Object.keys(service.updates).sort().reverse() : [];
  const lastUpdateTime = updates.length > 0 ? updates[0] : '';
  const lastUpdateValue = updates.length > 0 ? service.updates[lastUpdateTime].value : 'New';
  const lastUpdateName = updates.length > 0 ? service.updates[lastUpdateTime].displayName : 'New';
  const lastUpdateInitials = displayInitials(lastUpdateName)

  const addUpdate = () => {
    if (!!user) {
      console.log("Updating service...")
      const db = getAppDatabase();
      const date = now();
      set(ref(db, 'services/' + name + '/updates/' + date), {value,uid:user.uid,displayName:user.displayName});
    } else {
      console.log("No user. Cannot update...")
    }
  }

  function ServiceCard () {
    return (
      <Card className="card" variant="outlined" onClick={()=>setOpen(true)}>
        <CardHeader title={name}/>
        <CardContent>
          <h1>{lastUpdateValue}</h1>
          {Number(lastUpdateValue) ? service.units : ''}<br/>
          <span style={{color:'grey', fontSize:'smaller'}}>Updated {
            DateTime.fromISO(lastUpdateTime).toRelative()
          } by {
            lastUpdateInitials
          }</span>
        </CardContent>
      </Card>
    )
  } 
  
  return (
    <>
      <ServiceCard />
      <Dialog 
        fullWidth
        open={open}
        onClose={()=>setOpen(false)}
      >
        <DialogContent>
          <ServiceCard />
          {
            !!user ?
              <>
                <TextField
                  style={{marginTop:15}}
                  label={service.units}
                  value={value}
                  onChange={(event)=>setValue(event.target.value)}
                  fullWidth
                />
                <br/>
                <Button
                  fullWidth
                  style={{marginTop:5, marginBottom: 15}}
                  variant="contained"
                  size="large"
                  onClick={addUpdate}
                  >Update</Button>
                  <hr style={{width:'100%'}}/>
                </>
              :
              <>
              </>
          }
          <h2>History</h2>
          <List style={{maxHeight:'30vh',overflowY:'auto'}}>
          {
            updates.map((datetime)=>
              <ListItem key={datetime}>
                {DateTime.fromISO(datetime).toRelative()}&nbsp;
                :&nbsp;&nbsp;
                {service.updates[datetime].value}&nbsp;
                {Number(service.updates[datetime].value) ? service.units : ''}&nbsp;
                by {displayInitials(service.updates[datetime].displayName)}
              </ListItem>)
          }
          </List>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={()=>setOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  )

}

function NewService({user}) {

  const [open, setOpen] = React.useState(false);
  const [name, setName] = React.useState('');
  const [units, setUnits] = React.useState('');

  if (!user) {
    return (
      <>You must be logged in to create a new Service.</>
    )
  }

  const createService = () => {
    console.log("Starting a service...")
    console.log(`uid ${user.uid}`)
    console.log(`displayName ${user.displayName}`)
    const db = getAppDatabase();
    const updates = {}
    updates[now()] = { value: 'New', uid: user.uid, displayName: user.displayName };
    const tags = ['new']
    set(ref(db, 'services/' + name), { units, updates, tags });
    setOpen(false);
  }

  return (
    <>
      <Button onClick={()=>setOpen(true)}>Create a New Service</Button>
      <Dialog
        open={open}
        onClose={()=>setOpen(false)}
        >
          <DialogTitle>Create a New Service</DialogTitle>
          <DialogContent>
            <TextField
              style={{marginTop:15}}
              label="Name"
              value={name}
              onChange={(event)=>setName(event.target.value)}
              fullWidth
            />
            <TextField
              style={{marginTop:15}}
              label="Units"
              value={units}
              onChange={(event)=>setUnits(event.target.value)}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={()=>setOpen(false)}>Cancel</Button>
            <Button variant="contained" onClick={()=>createService()}>Create the {name} Service</Button>
          </DialogActions>
        </Dialog>
    </>
  )
}

function App() {

  const auth = getAppAuth();
  const [user, userLoading, userError] = useAuthState(auth);
  const db = getAppDatabase();
  const servicesRef = ref(db, 'services/');
  const [services, servicesLoading, servicesError] = useObjectVal(servicesRef);

  console.log(services)

  return (
    <div className="container">
      <h1 className="title">Wait Times.App</h1>
      {
        userLoading ? 
          <div>Loading...</div>
          : 
          !!user?
                <div style={{display: 'flex', flexDirection:'column', justifyContent: 'space-around',alignItems:'center',flexWrap:'wrap'}}>
                    <h3>Signed in as {user.displayName}</h3>
                    
                    <Button variant="outlined" onClick={()=>signOut(auth)}>Sign Out</Button>
                </div>
            :
              <SignIn />
          }
      
      <div className="grid">
        {
          servicesLoading ? 
            <div>Loading services...</div>
            :
            services && Object.keys(services).length > 0 ? 
              Object.entries(services).map(([key,service])=>{
                console.log(key)
                console.log(service)
                return <Service key={key} name={key} services={services} user={user} />
              })
              :
              <h1>No Services Defined</h1>
        }
      </div>

      {
        !!user ? 
        <NewService user={user} />:
        ''
      }


    </div>
  );
}

export default App;
