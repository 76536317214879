
import { getAppAuth } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

import { 
    getAuth, RecaptchaVerifier, signInWithPhoneNumber, updateProfile,
    onAuthStateChanged, signOut, GoogleAuthProvider,
    OAuthProvider, signInWithRedirect, getRedirectResult,
} from 'firebase/auth';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faEdit,
    faRotate,
  faUser, faUserCheck, 
} from '@fortawesome/free-solid-svg-icons';
import {
    faGoogle, faApple,
} from '@fortawesome/free-brands-svg-icons';

import { 
    Box, Button, TextField, 
} from '@mui/material';

export function SignIn() {
    const auth = getAppAuth();
    const [user, loading, error] = useAuthState(auth);
    const googleProvider = new GoogleAuthProvider();
    const appleProvider = new OAuthProvider('apple.com');

    const signInGoogle = () => {
        signInWithRedirect(auth, googleProvider);
    }

    const signInApple = () => {
        signInWithRedirect(auth, appleProvider);
    }

    getRedirectResult(auth)
        .then((result) => {

            // Apple credential
            const credential = OAuthProvider.credentialFromResult(result);
            if (credential) {
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
            }
            // The signed-in user info.
            const cuser = result.user;

            // ...

        })
        .catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            // The email of the user's account used.
            const email = error.email;
            // The credential that was used.
            const credential = OAuthProvider.credentialFromError(error);

            // ...
        });

    console.log(user)
  
    if (!user) {
        return (
            <Box style={{margin:10}}>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    <Button 
                        size="large"
                        variant="outlined" 
                        onClick={()=>signInGoogle()}
                    >Sign in using Google&nbsp;<FontAwesomeIcon icon={faGoogle} /></Button>
                    {/*
                    <br/>
                    <Button 
                        size="large"
                        variant="outlined" 
                        onClick={()=>signInApple()}
                    >Sign in using Apple&nbsp;<FontAwesomeIcon icon={faApple} /></Button>
                     */}
                </div>
            </Box>
        );
        }

    return (
        <div>
            <h3>Signed in as <FontAwesomeIcon icon={faUser} /> {user.displayName}</h3>
            
            <Button variant="contained" onClick={()=>signOut(auth)}>Sign Out</Button>
        </div>
    );
}

export default function UserView() {

    const auth = getAppAuth();

    const [user, loading, error] = useAuthState(auth);


    if (!!user) {
        return (
            <div>
                <h3>Signed in as <FontAwesomeIcon icon={faUser} /> {user.displayName}</h3>
                
                <Button variant="contained" onClick={()=>signOut(auth)}>Sign Out</Button>
            </div>
        );
    } else {
        return <div className="center-all"><SignIn /></div>;
    }
}